import React, { useState, useEffect } from "react";
import { environment } from "../../environment";
import FileUploader from "../../components/FileUploader";
import ReactRecaptcha3 from "react-google-recaptcha3";
import axios from "axios";
import PhoneInput from "react-phone-number-input/input";

const RecruitmentForm = (params) => {
  const [isLoading, setLoading] = useState(true);

  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("RecruitmentForm");
    console.log(params);
  }

  let agreeText = "";
  if (
    params.block["en"]["AgreeText"] !== "" &&
    params.block["en"].hasOwnProperty("AgreeText")
  ) {
    agreeText = params.block["en"]["AgreeText"];
  }

  let additionalText = "";
  if (
    params.block["en"]["AdditionalText"] !== "" &&
    params.block["en"].hasOwnProperty("AdditionalText")
  ) {
    additionalText = params.block["en"]["AdditionalText"];
  }

  let colorBack = "";
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "recruitment-form";
  let innerContainer = "container";
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  let captchaKey = "",
    dataToSend = {};

  if (
    origin.includes(environment.devName) ||
    origin.includes(environment.localhost)
  ) {
    captchaKey = "6LdDjBQgAAAAAGcbrt4z1XImLP07t6JgxPGmNcVd";
  } else if (origin.includes(environment.testName)) {
    captchaKey = "6LdDjBQgAAAAAGcbrt4z1XImLP07t6JgxPGmNcVd";
  } else if (origin.includes(environment.prodName)) {
    captchaKey = "6Lch2E0gAAAAANGDpyTeLLij8iYzrr3AFvZFIvKe";
  }

  useEffect(() => {
    ReactRecaptcha3.init(captchaKey).then((status) => { });
  }, []);

  const [enteredName, setEnteredName] = useState(""),
    [enteredNameTouched, setEnteredNameTouched] = useState(false),
    [enteredEmail, setEnteredEmail] = useState(""),
    [enteredEmailTouched, setEnteredEmailTouched] = useState(false),
    [enteredPhone, setEnteredPhone] = useState(""),
    [enteredPhoneTouched, setEnteredPhoneTouched] = useState(false),
    [enteredPosition, setEnteredPosition] = useState(""),
    [enteredPositionTouched, setEnteredPositionTouched] = useState(false),
    [agreeRodo, setAgreeRodo] = useState(false),
    [aggreRodoClassControler, setAgreeRodoClassControler] = useState(""),
    [submitMessage, setSubmitMessage] = useState(
      "Twoja aplikacja została wysłana. Dziękujemy."
    ),
    [submitmessageVisible, setSubmitMessageVisible] = useState(false),
    [errorMessageVisible, setErrorMessageVisible] = useState(false),
    [cvFile, setCvFile] = useState([]),
    [letterFile, setLetterFile] = useState([]),
    [allowedMimeTypes, setAllowedMimeTypes] = useState([]),
    [cvFileClassControler, setCvClassControler] = useState(""),
    [maxAllowedSize, setMaxAllowedSize] = useState(null), //in bytes
    [errorMessage, setErrorMessage] = useState(''),
    [wrongFile, setWrongFile] = useState(false);

  const getConfigData = () => {
    axios
      .get(environment.siteUrlDev + "/forms-api/career/getConfig")
      .then((response) => {
        const res = response.data;

        const getDataForSelect = (obj) => {
          let arr = [];

          for (var i in obj) {
            arr.push({ value: i, label: obj[i] });
          }
          return arr;
        };

        setAllowedMimeTypes([...res.allowedMimeTypes]);
        setMaxAllowedSize(res.maxAllowedSize);

        setLoading(false);
      });
  };

  useEffect(() => {
    getConfigData(); //fetching config data
    ReactRecaptcha3.init(captchaKey).then((status) => { });
  }, []);

  useEffect(() => {
  if (errorMessage.length > 0) {
      setErrorMessageVisible(true)
      if (wrongFile && wrongFile === "CV") {
        setCvFile([]);
      } 
      if (wrongFile && wrongFile === "Other") {
        setLetterFile([]);
      }
    }
    const timer = setTimeout(() => setErrorMessageVisible(false), 10000);

    return () => {
        clearTimeout(timer);
    };
  }, [errorMessage]);

  useEffect(() => {
    const timer = setTimeout(() => setSubmitMessageVisible(false), 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [submitmessageVisible]);

  let reemail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const rephone = /^[0-9\-\+]{9,16}$/;

  const enteredNameIsValid = enteredName.trim() !== "";
  const nameInputIsInvalid = !enteredNameIsValid && enteredNameTouched;

  const enteredEmailIsValid =
    enteredEmail.trim() !== "" && reemail.test(enteredEmail);
  const emailInputIsInvalid = !enteredEmailIsValid && enteredEmailTouched;

  const enteredPhoneIsValid =
    enteredPhone.trim() !== "" && rephone.test(enteredPhone);
  const phoneInputIsInvalid = !enteredPhoneIsValid && enteredPhoneTouched;

  const enteredPositionIsValid = enteredPosition.trim() !== "";
  const positionInputIsInvalid =
    !enteredPositionIsValid && enteredPositionTouched;

  let formIsValid = false;
  if (
    enteredNameIsValid &&
    enteredEmailIsValid &&
    enteredPhoneIsValid &&
    enteredPositionIsValid &&
    agreeRodo
  ) {
    formIsValid = true;
  }

  const nameChangeHandler = (e) => {
    setEnteredName(e.target.value);
  };

  const nameInputBlurHandler = () => {
    setEnteredNameTouched(true);
  };

  const emailChangeHandler = (e) => {
    setEnteredEmail(e.target.value);
  };

  const emailInputBlurHandler = () => {
    setEnteredEmailTouched(true);
  };

  const phoneChangeHandler = (number) => {
    setEnteredPhone(number);
  };

  const phoneInputBlurHandler = () => {
    setEnteredPhoneTouched(true);
  };

  const positionChangeHandler = (e) => {
    setEnteredPosition(e.target.value);
  };

  const positionInputBlurHandler = () => {
    setEnteredPositionTouched(true);
  };

  const statusHandlerRodo = () => {
    setAgreeRodo(!agreeRodo);
    setAgreeRodoClassControler("");
  };

  const cvFileUploadHandler = (uploadedFiles) => {
    let files = {};
    uploadedFiles.forEach((file, index) => {
      if(file.name == letterFile.name) {
        setErrorMessage('Załadowałeś ten sam plik dwa razy');
        return;
      }
      files["file" + (index + 1)] = file;
    });
    if (!Object.keys(files).length) {
      files.file1 = [];
    }
    setCvFile(files.file1);
    setCvClassControler("");
  };

  const letterFileUploadHandler = (uploadedFiles) => {
    let files = {};
    uploadedFiles.forEach((file, index) => {
      if(file.name == cvFile.name) {
        setErrorMessage('Załadowałeś ten sam plik dwa razy');
        return;
      }
      files["file" + (index + 1)] = file;
    });
    if (!Object.keys(files).length) {
      files.file1 = [];
    }
    setLetterFile(files.file1);
  };

  const handleClickScroll = () => {
    setTimeout(function () {
        const element = document.getElementById('recru-form-scroll');
        if (element) {
            element.scrollIntoView({ block: 'start' });
        }
    }, 200)
};

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setEnteredNameTouched(true);
    setEnteredEmailTouched(true);
    setEnteredPhoneTouched(true);
    setEnteredPositionTouched(true);

    if (
      !enteredNameIsValid ||
      !enteredEmailIsValid ||
      !enteredPhoneIsValid ||
      !enteredPositionIsValid ||
      !agreeRodo ||
      (!cvFile || cvFile == null || cvFile.length == 0)
    ) {
      setErrorMessageVisible(true);
      setErrorMessage("Wypełnij wymagane pola")
      if (!agreeRodo) {
        setAgreeRodoClassControler("error");
      }

      if (cvFile.length === 0) {
        setCvClassControler("error");
      }

      return;
    }

    dataToSend = {
      email: enteredEmail,
      phone: enteredPhone,
      name: enteredName,
      agree: agreeRodo,
      position: enteredPosition,
      cv: cvFile,
      letter: letterFile,
    };

    ReactRecaptcha3.getToken().then((token) => {
      dataToSend = {
        ...dataToSend,
        "g-recaptcha-response": token,
      };

      let url = "/forms-api/career/send";

      if (origin.includes(environment.localhost)) {
        url = environment.siteUrlDev + url;
      }

      axios
        .post(url, dataToSend, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const success = response.data.message;

          if (!success) {
            setSubmitMessage(
              "Wystąpił błąd w wysyłce formularza. Spróbuj ponownie."
            );
            setSubmitMessageVisible(true);
          } else {
            setEnteredNameTouched(false);
            setEnteredEmailTouched(false);
            setEnteredPhoneTouched(false);
            setEnteredPositionTouched(false);
            setErrorMessageVisible(false);
            setEnteredName("");
            setEnteredEmail("");
            setEnteredPhone("");
            setEnteredPosition("");
            setSubmitMessageVisible(true);
            setAgreeRodo(false);
            setCvFile([]);
            setLetterFile([]);
          }
        });
    });
  };

  const inputNameControl = nameInputIsInvalid ? "error" : "";
  const inputNameActiveControl = enteredNameTouched ? "active" : "";

  const inputEmailControl = emailInputIsInvalid ? "error" : "";
  const inputEmailActiveControl = enteredEmailTouched ? "active" : "";

  const inputPhoneControl = phoneInputIsInvalid ? "error" : "";
  const inputPhoneActiveControl = enteredPhoneTouched ? "active" : "";

  const inputPositionControl = positionInputIsInvalid ? "error" : "";
  const inputPositionActiveControl = enteredPositionTouched ? "active" : "";

  return (
    <section className={`recruitment-form ${innerSection}`}>
      <div id="recru-form-scroll" className="wrapper">
        <div  className={`container ${innerContainer}`}>
          {params.block["en"]["Title"] && (
            <h2>{params.block["en"]["Title"]}</h2>
          )}
          <form
            onSubmit={formSubmitHandler}
            className="form-box"
            id="recruitment-form"
            name="sentMessage"
            action="/career/send"
            method="post"
          >
            {errorMessageVisible && (
              <div className={`message errors`}>
                <span>{errorMessage ? errorMessage : "Wystąpił błąd"}</span>
              </div>
            )}
            {submitmessageVisible && !errorMessageVisible && (
              <div className="message">
                <span className="mr-3">{submitMessage}</span>
              </div>
            )}
            <div className="grid-area">
              <fieldset className={inputNameControl}>
                <input
                  id="name"
                  type="text"
                  className="w-100 required"
                  name="name"
                  value={enteredName}
                  onChange={nameChangeHandler}
                  onBlur={nameInputBlurHandler}
                />

                <span className={`placeholder ${inputNameActiveControl}`}>
                  Imię i nazwisko
                </span>
              </fieldset>
              <fieldset className={inputEmailControl}>
                <input
                  id="email"
                  type="text"
                  className="w-100 required"
                  name="email"
                  value={enteredEmail}
                  onChange={emailChangeHandler}
                  onBlur={emailInputBlurHandler}
                />
                <span className={`placeholder ${inputEmailActiveControl}`}>
                  Adres e-mail
                </span>
              </fieldset>
              <fieldset className={`phone ${inputPhoneControl}`}>
                <PhoneInput
                  country="PL"
                  international
                  withCountryCallingCode
                  value={enteredPhone}
                  onChange={(number) => {
                    phoneChangeHandler(number);
                  }}
                  onBlur={phoneInputBlurHandler}
                  maxLength="16"
                  className="w-100 PhoneInputInput required"
                  id="phone"
                />

                <span className={`placeholder ${inputPhoneActiveControl}`}>
                  Numer telefonu
                </span>
              </fieldset>
            </div>

            <div className="grid-area">
              <fieldset className={inputPositionControl}>
                <input
                  type="text"
                  maxLength="40"
                  className="w-100 required"
                  value={enteredPosition}
                  onChange={positionChangeHandler}
                  onBlur={positionInputBlurHandler}
                />

                <span className={`placeholder ${inputPositionActiveControl}`}>
                  Aplikacja na stanowisko
                </span>
              </fieldset>
              <fieldset className={cvFileClassControler}>
                <FileUploader
                  id="CV"
                  accepted={[
                    "application/pdf",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  ]}
                  maxFileSize={2097152}
                  maxFiles={1}
                  text={"CV (.pdf/.docx)"}
                  filesToSend={cvFileUploadHandler}
                  uploaded={cvFile}
                  setErrorMessage={setErrorMessage}
                  setWrongFile={setWrongFile}
                />
              </fieldset>
              <fieldset>
                <FileUploader
                  id="Letter"
                  accepted={[
                    "application/pdf",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  ]}
                  maxFileSize={2097152}
                  maxFiles={1}
                  text={"List (.pdf/.docx)"}
                  filesToSend={letterFileUploadHandler}
                  uploaded={letterFile}
                  setErrorMessage={setErrorMessage}
                  setWrongFile={setWrongFile}
                />
              </fieldset>
            </div>

            <div id="rodo" className="grid-area checkbox-container">
              <label className={`xx-small ${aggreRodoClassControler}`}>
                <input
                  className="agree"
                  name="agreeRodo"
                  id="rodo"
                  type="checkbox"
                  checked={agreeRodo}
                  onChange={statusHandlerRodo}
                />
                <span>&nbsp;</span>
                {agreeText && (
                  <span
                    className="text"
                    dangerouslySetInnerHTML={{ __html: agreeText }}
                  ></span>
                )}
              </label>
            </div>
            <div
              id="recruiter-form-desc"
              className="grid-area checkbox-container"
            >
              <label className="xx-small d-flex f-column ">
                <span>&nbsp;</span>
                {additionalText && (
                  <span
                    className="text"
                    dangerouslySetInnerHTML={{ __html: additionalText }}
                  ></span>
                )}
              </label>
            </div>
            <div className="grid-area submit ta-center">
              <button onClick={handleClickScroll} className="button primary" type="submit">
                Wyślij wiadomość
              </button>
            </div>
            <div className="ta-center m-auto">&nbsp;</div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default RecruitmentForm;

import React, { useState } from "react";
import { environment } from "./../../environment";
import Links from "../../components/Links";
import Image from "../../components/Image";
import LegalNote from "../../components/LegalNote";
import TextLinks from "../../components/TextLinks";
const TopBanner = (params) => {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
      console.log("TopBanner");
      console.log(params);
  }

    const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                    <g>
                        <path fill="#2DA343" d="M7.415,8.44H2.064c-0.553,0-1-0.448-1-1V3.675c0-2.027,1.649-3.676,3.676-3.676   c2.026,0,3.676,1.649,3.676,3.676v3.764C8.416,7.992,7.968,8.44,7.415,8.44z M3.065,6.439h3.349V3.675C6.414,2.751,5.663,2,4.74,2   C3.816,2,3.065,2.751,3.065,3.675V6.439z"></path>
                        <path fill="#2DA343" d="M21.935,24.001H7.415c-0.553,0-1.001-0.448-1.001-1.001V1c0-0.553,0.448-1,1.001-1h14.521   c0.553,0,1.001,0.448,1.001,1v22C22.936,23.553,22.488,24.001,21.935,24.001z M8.416,21.999h12.519V2H8.416V21.999z"></path>
                        <rect x="4.74" y="-0.001" fill="#2DA343" width="2.675" height="2.001"></rect>
                        <path fill="#2DA343" d="M17.269,7.446h-5.188c-0.553,0-1.001-0.448-1.001-1s0.448-1,1.001-1h5.188c0.553,0,1.001,0.448,1.001,1   S17.822,7.446,17.269,7.446z"></path>
                        <path fill="#2DA343" d="M17.269,12.727h-5.188c-0.553,0-1.001-0.448-1.001-1.001s0.448-1.001,1.001-1.001h5.188   c0.553,0,1.001,0.448,1.001,1.001S17.822,12.727,17.269,12.727z"></path>
                        <path fill="#2DA343" d="M17.269,18.008h-5.188c-0.553,0-1.001-0.448-1.001-1.001s0.448-1.001,1.001-1.001h5.188   c0.553,0,1.001,0.448,1.001,1.001S17.822,18.008,17.269,18.008z"></path>
                    </g>
                </svg>`;
   
    let Images = params.block['en'].hasOwnProperty('Image') ? (params.block['en']['Image'].hasOwnProperty('link') ? params.block['en']['Image']['link'] : environment.defaultImage) : environment.defaultImage,
        Tag = params.block['en'].hasOwnProperty('Tag') ? params.block['en']['Tag'] : '',
        Title = params.block['en'].hasOwnProperty('Title') ? params.block['en']['Title'] : '',
        Text = params.block['en'].hasOwnProperty('Text') ? params.block['en']['Text'] : '',
        Description = params.block['en'].hasOwnProperty('Description') ? params.block['en']['Description'] : '',
        LinkFirst = params.block['en'].hasOwnProperty('LinkFirst') ? params.block['en']['LinkFirst']['link'] : '',
        LinkNameFirst = params.block['en'].hasOwnProperty('LinkNameFirst') ? params.block['en']['LinkNameFirst'] : '',
        LegalNotes = params.block['en'].hasOwnProperty('LegalNote') ? params.block['en']['LegalNote'] : '',
        InNewOpen = params.block['en'].hasOwnProperty('InNewOpen') ? (params.block['en']['InNewOpen'] ? 'true' : 'false') : 'false',
        linkNav = params.block['en'].hasOwnProperty('Item') ? params.block['en']['Item'] : [];
    if (params.hasOwnProperty('out') && params.out) {
        Title = params.title;
        Text = params.teaser;
        Images = params.image;
    }
    let innerSection = 'top-banner container';
    let innerContainer = 'container-wide';
    if (params.block['en']['InnerBackground'] !== '') {
        innerSection = innerSection + ' ';
    }
    let [navActive, setNavActive] = useState("");
    function activeNav(nav) {
        let status = '',
            url = window.location.pathname.includes('index.html') ? window.location.pathname.replace('index.html', '') : window.location.pathname;
        if (url === nav) {
          status = ' active'
        }
        return status
    }
    return (
        <section aria-label="section" className={innerSection}>
  		    <div className="wrapper">
                <div className={innerContainer}>
                    <div className="w-100">
                        <div className="main-box d-flex">
                            <div className="d-flex w-100 item active">
                                <div className="item-main-box d-flex ai-center w-50">
                                    <div className="text-box-inner p-50">
                                      <div className="content active">
                                        {Tag && 
                                            <p className="tag">{Tag}</p>
                                        }
                                        {Title && 
                                            <h1>{Title}</h1>
                                        }
                                        {Text && 
                                            <div className="text mt-30">
                                                <TextLinks text={Text} line={params.line} />
                                            </div>
                                        }
                                        {Description && 
                                            <div className="description text mt-30">
                                                <TextLinks text={Description} line={params.line} />          
                                            </div>                                
                                        }
                                        <div className="button-box mt-20">
                                            {LinkFirst && 
                                                <Links
                                                    target={`${InNewOpen}`}
                                                    url={LinkFirst}
                                                    name={LinkNameFirst}
                                                    class="button light mr-20"
                                                    line={params.line}
                                                />
                                            }
                                            {LegalNotes && 
                                                <LegalNote LegalNote={LegalNotes} classButton="button light icon-btn disclaimer" svg={svg} />                   
                                            }
                                        </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-50 image">
                                    <Image src={Images} alt={Title ? Title : "Bank Rumia Spółdzielczy - banner"} class="w-100 h-100 active" />
                                </div>
                            </div>
                        </div>
                        {linkNav.length > 0 && 
                            <div className="submenu links-item d-flex jc-around w-100">
                                <ul className="b-green d-flex jc-between">
                                    {linkNav.map((item, i) => {
                                        if (item.hasOwnProperty('Link') && item.hasOwnProperty('LinkName')) {
                                            if (item.Link.hasOwnProperty('path') && item.LinkName !== '') {
                                                return (
                                                    <li className={`dropdown` + activeNav(item.Link.path)} onClick={(e) => {setNavActive(item.Link.path)}} data-link={navActive} key={'banner-links-'+i}>
                                                        <Links url={item.Link} name={item.LinkName} class="menu-item-href" line={params.line} />
                                                    </li>
                                                )
                                            }
                                            else {
                                                return ''
                                            }                                      
                                        }
                                        else {
                                            return ''
                                        }
                                    })}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopBanner;
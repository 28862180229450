import React, {useState} from "react";
import { environment } from "./../../environment";
import Links from "../../components/Links";
import Image from "../../components/Image";
import LegalNote from "../../components/LegalNote";

function TopBannerCarouselItem(params) {
    
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
    <g>
        <path fill="#2DA343" d="M7.415,8.44H2.064c-0.553,0-1-0.448-1-1V3.675c0-2.027,1.649-3.676,3.676-3.676   c2.026,0,3.676,1.649,3.676,3.676v3.764C8.416,7.992,7.968,8.44,7.415,8.44z M3.065,6.439h3.349V3.675C6.414,2.751,5.663,2,4.74,2   C3.816,2,3.065,2.751,3.065,3.675V6.439z"></path>
        <path fill="#2DA343" d="M21.935,24.001H7.415c-0.553,0-1.001-0.448-1.001-1.001V1c0-0.553,0.448-1,1.001-1h14.521   c0.553,0,1.001,0.448,1.001,1v22C22.936,23.553,22.488,24.001,21.935,24.001z M8.416,21.999h12.519V2H8.416V21.999z"></path>
        <rect x="4.74" y="-0.001" fill="#2DA343" width="2.675" height="2.001"></rect>
        <path fill="#2DA343" d="M17.269,7.446h-5.188c-0.553,0-1.001-0.448-1.001-1s0.448-1,1.001-1h5.188c0.553,0,1.001,0.448,1.001,1   S17.822,7.446,17.269,7.446z"></path>
        <path fill="#2DA343" d="M17.269,12.727h-5.188c-0.553,0-1.001-0.448-1.001-1.001s0.448-1.001,1.001-1.001h5.188   c0.553,0,1.001,0.448,1.001,1.001S17.822,12.727,17.269,12.727z"></path>
        <path fill="#2DA343" d="M17.269,18.008h-5.188c-0.553,0-1.001-0.448-1.001-1.001s0.448-1.001,1.001-1.001h5.188   c0.553,0,1.001,0.448,1.001,1.001S17.822,18.008,17.269,18.008z"></path>
    </g>
    </svg>`

    let Images = params.block.hasOwnProperty('Image') ? (params.block['Image'].hasOwnProperty('link') ? params.block['Image']['link'] : environment.defaultImage) : environment.defaultImage,
        Tag = params.block.hasOwnProperty('Tag') ? params.block['Tag'] : '',
        Title = params.block.hasOwnProperty('Title') ? params.block['Title'] : '',
        Text = params.block.hasOwnProperty('Text') ? params.block['Text'] : '',
        LinkFirst = params.block.hasOwnProperty('LinkFirst') ? params.block['LinkFirst']['link'] : '',
        LinkNameFirst = params.block.hasOwnProperty('LinkNameFirst') ? params.block['LinkNameFirst'] : '',
        LegalNotes = params.block.hasOwnProperty('LegalNote') ? params.block['LegalNote'] : '',
        InNewOpen = params.block.hasOwnProperty('InNewOpen') ? (params.block['InNewOpen'] ? 'true' : 'false') : 'false',
        isVis = params.classActive ? "content active" : "content",
        isVis2 = params.classActive ? "w-100 h-100 active" : "w-100 h-100";

        if (params.typeItem === "content") {
            return (
                <div className={isVis} role="group" aria-labelledby={params.classActive ? 'topbannercarousel-active' : ""}>
                    {Tag && 
                        <p className="tag">{Tag}</p>
                    }
                    {Title && 
                        <h2>
                            {Title}
                        </h2>
                    }
                    {Text && 
                        <p className="text mb-30 mt-30">
                            {Text}
                        </p>
                    }
                    <div className="button-box mt-20">
                        {LinkFirst && 
                            <Links
                            target={`${InNewOpen}`}
                            url={LinkFirst}
                            name={LinkNameFirst}
                            class="button light mr-20"
                            line={params.line}
                          />
                        }
                        {LegalNotes && 
                            <LegalNote LegalNote={LegalNotes} classButton="button light icon-btn disclaimer" svg={svg} setActiveLegalNote={params.legalNote}  />                   
                        }
                    </div>
                </div>
            )
        }

        if (params.typeItem === "image") {
            return (
                <Image src={Images} alt={Title ? Title : "Bank Rumia Spółdzielczy - banner"} class={isVis2} />
            )
        }
}

export default TopBannerCarouselItem;